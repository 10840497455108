import type PurchaseOrder from './PurchaseOrder.js'
import PurchaseOrderDocumentLine from './PurchaseOrderDocumentLine.js'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import type PurchaseOrderLine from './PurchaseOrderLine.js'
import type PurchaseOrderHistory from './PurchaseOrderHistory.js'
import type PurchaseOrderLineHistory from './PurchaseOrderLineHistory.js'
import type Customer from './Customer.js'
import type Vendor from './Vendor.js'
import type Inventory from './Inventory.js'

const valueOrElse = (val: any, defaultVal: any = '') => (val ? val : defaultVal)

export default class PurchaseOrderDocument {
	constructor({
		purchaseOrder,
		purchaseOrderLines,
		purchaseOrderHistory,
		purchaseOrderLineHistory,
		customer,
		vendor,
		inventoryList,
		saveState,
	}: {
		purchaseOrder: PurchaseOrder
		purchaseOrderLines: PurchaseOrderLine[]
		purchaseOrderHistory: PurchaseOrderHistory[]
		purchaseOrderLineHistory: PurchaseOrderLineHistory[]
		customer: Customer | null
		vendor: Vendor | null
		inventoryList: Inventory[]
		saveState: 'NEW' | 'UNMODIFIED' | 'DELETE'
	}) {
		this.purchaseOrderId = purchaseOrder ? valueOrElse(purchaseOrder.purchaseOrderId, null) : null
		this.storeId = valueOrElse(purchaseOrder.storeId, null)
		this.customer = customer
		this.vendor = valueOrElse(vendor, null)
		// this.status = valueOrElse(purchaseOrder.status, 'NEW')
		this.documentType = valueOrElse(purchaseOrder.documentType, 'Purchase Order')
		this.documentDate = purchaseOrder.documentDate ? purchaseOrder.documentDate : purchaseOrder.creationDate
		this.creationDate = purchaseOrder.creationDate
		this.salesperson = purchaseOrder.salesperson
		this.tax = valueOrElse(purchaseOrder.tax, 0)
		this.subtotal = valueOrElse(purchaseOrder.subtotal, 0)
		this.total = valueOrElse(purchaseOrder.total, 0)
		this.totalReceived = valueOrElse(purchaseOrder.totalReceived, 0)
		this.isPaid = purchaseOrder.isPaid ? isTrue(purchaseOrder.isPaid) : false
		this.checkNumber = purchaseOrder.checkNumber ?? ''
		this.isClosed = purchaseOrder.isClosed ? isTrue(purchaseOrder.isClosed) : false
		this.didNotify = purchaseOrder.didNotify ? isTrue(purchaseOrder.didNotify) : false
		this.comments = valueOrElse(purchaseOrder.comments, '')
		this.lastModifiedDate = purchaseOrder.lastModifiedDate
		this.shippingCompany = valueOrElse(purchaseOrder.shippingCompany, '')
		this.shippingContact = valueOrElse(purchaseOrder.shippingContact, '')
		this.shippingStreetAddress = valueOrElse(purchaseOrder.shippingStreetAddress, '')
		this.shippingMailingAddress = valueOrElse(purchaseOrder.shippingMailingAddress, '')
		this.shippingCity = valueOrElse(purchaseOrder.shippingCity, '')
		this.shippingState = valueOrElse(purchaseOrder.shippingState, '')
		this.shippingZip = valueOrElse(purchaseOrder.shippingZip, '')
		this.shippingCountry = valueOrElse(purchaseOrder.shippingCountry, '')
		this.shippingPhoneNumber = valueOrElse(purchaseOrder.shippingPhoneNumber, '')
		this.inventoryList = inventoryList
		this.purchaseOrderLines = purchaseOrderLines.map(
			purchaseOrderLine =>
				new PurchaseOrderDocumentLine({
					purchaseOrderLine,
					saveState,
				})
		)
		this.purchaseOrderHistory = purchaseOrderHistory
		this.purchaseOrderLineHistory = purchaseOrderLineHistory
		this.saveState = saveState
	}

	purchaseOrderId: number | null
	storeId: number
	customer: Customer | null
	vendor: Vendor
	// status: string
	documentType: string
	documentDate: string
	creationDate: string
	salesperson: string
	tax: string
	subtotal: string
	total: string
	totalReceived: string
	isPaid: boolean
	checkNumber: string
	isClosed: boolean
	didNotify: boolean
	comments: string
	lastModifiedDate: string
	shippingCompany: string
	shippingContact: string
	shippingStreetAddress: string
	shippingMailingAddress: string
	shippingCity: string
	shippingState: string
	shippingZip: string
	shippingCountry: string
	shippingPhoneNumber: string
	inventoryList: Inventory[]
	purchaseOrderLines: PurchaseOrderDocumentLine[]
	purchaseOrderHistory: PurchaseOrderHistory[]
	purchaseOrderLineHistory: PurchaseOrderLineHistory[]
	saveState: 'NEW' | 'UNMODIFIED' | 'DELETE'
}
