import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import SalesOrderDocumentLine from './SalesOrderDocumentLine.mjs'
import getAutomaticDescription from '../document-line-description-builder.js'

export default class SalesOrderDocumentLineFromInventory {
	constructor({ saleDocument, inventory, inventoryType, documentType, saveState, vehicle }) {
		const description = getAutomaticDescription(inventory.description, inventoryType?.name, {
			year: inventory.year,
			make: inventory.make,
			model: inventory.model,
			partManufacturer: inventory.partManufacturer,
			partModel: inventory.partModel,
			side: inventory.side,
		})

		const saleDocumentLine = {
			tagNumber: inventory.tagNumber,
			trackingNumber: (vehicle && vehicle.trackingNumber) ? vehicle.trackingNumber : '',
			partManufacturer: inventory.partManufacturer,
			partModel: inventory.partModel,
			vin: inventory.vin,
			vehicleMake: inventory.make,
			vehicleModel: inventory.model,
			vehicleYear: inventory.year,
			vehicleBodyStyle: inventory.bodyStyle,
			description,
			interchangeNumber: inventory.interchangeNumber,
			cost: inventory.cost,
			price: inventory.customerPrice ? inventory.customerPrice : inventory.retailPrice,
			coreAmount: inventory.core,
			quantity: 1,
			inventoryId: inventory.inventoryId > 0 ? inventory.inventoryId : null,
			vehicleId: inventory.vehicleId > 0 ? inventory.vehicleId : null,
			rank: null,
			isHeld: documentType === 'INVOICE' ? null : true,
			isTaxed: isTrue(inventory.isTaxable),
		}

		return new SalesOrderDocumentLine({
			saleDocument,
			documentType,
			saleDocumentLine,
			saveState,
		})
	}
}
