export default function getAutomaticDescription(partDescription: string | null, inventoryTypeName: string | null, otherInfo = {}) {
	partDescription = partDescription ? partDescription.trim() : ''
	inventoryTypeName = inventoryTypeName ? inventoryTypeName.trim() : ''

	let otherInfoArray = Object.values(otherInfo)
		.map(item => (item ? item : ''))
		.reduce((sum: Array<any>, item) => {
			item = item.toString().trim()
			return item ? sum.concat(item) : sum
		}, [])

	let otherInfoString = otherInfoArray.length > 0 ? ` (${otherInfoArray.join(' ')})` : ``

	return [inventoryTypeName, otherInfoString, partDescription]
		.filter(v => v)
		.join(' ')
		.trim()
}
