import template from './purchase-order.html'
import * as dateTimeUtility from '@isoftdata/utility-date-time'
import { stringToBoolean } from '@isoftdata/utility-string'
import pProps from 'p-props'

//Ractive components
import makeYesNoModal from '@isoftdata/yes-no-modal'
import makeDateRange from '@isoftdata/date-range'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

export default function({ mediator, logAndAlert, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.purchase-order',
		route: 'purchase-order',
		querystringParameters: [ 'id', 'entityContext', 'selectedPurchaseOrderId', 'selectedDateRange', 'fromDate', 'toDate', 'showVoid' ],
		defaultParameters: { entityContext: 'vendor', showVoid: false },
		template: {
			template,
			components: {
				itDateRange: makeDateRange(),
				itTable: makeTable(),
				itButton: makeButton(),
				itYesNoModal: makeYesNoModal(),
			},
			computed: {
				selectedPurchaseOrder() {
					const selectedPurchaseOrderId = this.get('selectedPurchaseOrderId')

					return this.get('purchaseOrderList')
						.find(({ purchaseOrderId }) => selectedPurchaseOrderId === purchaseOrderId)
				},
			},
		},
		resolve(data, parameters) {
			let { id, entityContext, fromDate, toDate, selectedPurchaseOrderId, showVoid } = parameters

			if (!fromDate && !toDate) {
				const defaultDates = dateTimeUtility.datesFromRange('Last 90 Days')

				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				}
			}

			showVoid = stringToBoolean(showVoid)

			const purchaseOrderLoadOptions = {
				showVoid,
				vendorId: id,
				fromDate,
				toDate,
			}

			return pProps({
				showVoid,
				range: dateTimeUtility.rangeFromDates(parameters.fromDate, parameters.toDate),
				dates: { from: parameters.fromDate, to: parameters.toDate },
				selectedPurchaseOrderId: selectedPurchaseOrderId ? Number(selectedPurchaseOrderId) : null,
				purchaseOrderList: (id && entityContext) ? mediator.call('emitToServer', 'load purchase orders', purchaseOrderLoadOptions) : [],
			})
		},
		activate({ domApi: ractive }) {
			ractive.on('void', async() => {
				const purchaseOrderId = ractive.get('selectedPurchaseOrderId')

				if (purchaseOrderId) {
					try {
						const voided = await mediator.call('emitToServer', 'void purchase order', purchaseOrderId)

						if (voided) {
							stateRouter.go('app.redirect', { state: 'app.purchase-order' })
						}
					} catch (err) {
						logAndAlert(err, mediator, 'Error voiding purchase order')
					}
				}
			})

			ractive.observe('showVoid', showVoid => {
				stateRouter.go(null, { showVoid }, { inherit: true })
			}, { init: false })

			ractive.observe('range dates', ({ from, to }) => {
				stateRouter.go(null, { fromDate: from, toDate: to }, { inherit: true })
			}, { init: false })

			ractive.observe('selectedPurchaseOrderId', selectedPurchaseOrderId => {
				stateRouter.go('app.entity.purchase-order.line', { selectedPurchaseOrderId }, { inherit: true })
			}, { init: false })
		},
	})
}
