import { v4 as uuid } from '@lukeed/uuid'
import type PurchaseOrderLine from './PurchaseOrderLine.js'

const valueOrElse = (val: any, defaultVal: any = '') => (val ? val : defaultVal)

export default class PurchaseOrderDocumentLine {
	constructor({ purchaseOrderLine, saveState }: { purchaseOrderLine: PurchaseOrderLine; saveState: 'NEW' | 'UNMODIFIED' | 'DELETE' }) {
		this.purchaseOrderId = valueOrElse(purchaseOrderLine.purchaseOrderId, null)
		this.purchaseOrderLineId = purchaseOrderLine.purchaseOrderLineId
		this.tagNumber = ''
		this.inventoryId = valueOrElse(purchaseOrderLine.inventoryId, null)
		this.description = valueOrElse(purchaseOrderLine.description)
		this.price = valueOrElse(purchaseOrderLine.price, 0)
		this.coreAmount = valueOrElse(purchaseOrderLine.coreAmount, 0)
		this.quantity = valueOrElse(purchaseOrderLine.quantity, 1)
		this.quantityReceived = valueOrElse(purchaseOrderLine.quantityReceived, 0)
		this.newReceived = 0
		this.printTag = false
		this.tagPerQuantity = false
		/*
		//The schema exposes these tax fields, but they're not used by anything
		this.isTaxed = isTrue(purchaseOrderLine.isTaxed)
		this.taxAmount = valueOrElse(purchaseOrderLine.taxAmount, 0)
		*/
		this.rank = valueOrElse(purchaseOrderLine.rank, 0)
		this.uuid = uuid()
		this.saveState = saveState
	}

	purchaseOrderId: string | null
	purchaseOrderLineId: number
	tagNumber: string
	inventoryId: string | null
	description: string
	price: string
	coreAmount: string
	quantity: string
	quantityReceived: string
	newReceived: number
	printTag: boolean
	tagPerQuantity: boolean
	rank: number
	uuid: string
	saveState: 'NEW' | 'UNMODIFIED' | 'DELETE'
}
