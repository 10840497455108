import PurchaseOrderDocumentLine from './PurchaseOrderDocumentLine.js'
import getAutomaticDescription from '../document-line-description-builder.js'
import type PurchaseOrder from './PurchaseOrder.js'
import type Inventory from './Inventory.js'
import type InventoryType from './InventoryType.js'
import type PurchaseOrderLine from './PurchaseOrderLine.js'

export default class PurchaseOrderDocumentLineFromInventory {
	constructor({
		purchaseOrder,
		inventory,
		inventoryType,
		saveState,
	}: {
		purchaseOrder: PurchaseOrder
		inventory: Inventory
		inventoryType: InventoryType
		saveState: 'NEW' | 'UNMODIFIED' | 'DELETE'
	}) {
		const description = getAutomaticDescription(inventory.description, inventoryType?.name, {
			year: inventory.year,
			make: inventory.make,
			model: inventory.model,
			partManufacturer: inventory.partManufacturer,
			partModel: inventory.partModel,
			side: inventory.side,
		})

		const purchaseOrderLine = {
			purchaseOrderId: purchaseOrder.purchaseOrderId,
			inventoryId: inventory.inventoryId,
			description,
			price: inventory.cost,
			coreAmount: inventory.core,
			quantity: 1,
			quantityReceived: 0,
		} as PurchaseOrderLine //TODO: Stop lying at some point

		return new PurchaseOrderDocumentLine({
			purchaseOrderLine,
			saveState,
		})
	}
}
