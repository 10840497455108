
import template from './purchase-order.html'
import pProps from 'p-props'
import * as currency from '@isoftdata/utility-currency'
import financialNumber from 'financial-number'
import domValue from 'dom-value'
import { v4 as uuid } from '@lukeed/uuid'
import formatDate from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import { parseISO } from 'date-fns'
import ractiveTransitionsFly from 'ractive-transitions-fly'

//Ractive Components
import makePurchaseOrderLineRow from 'components/purchase-order-line-row'
import makeEntitySelectionModal from 'components/entity-selection-modal'
import makeDocumentLoadModal from 'components/document-load-modal'
import makeReportSelection from 'components/report-selection'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeAddressCard from '@isoftdata/address-card'
import makeSplitButton from '@isoftdata/split-button'
import makeYesNoModal from '@isoftdata/yes-no-modal'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextArea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'

//Classes
import PurchaseOrderDocument from 'shared/classes/PurchaseOrderDocument'
import PurchaseOrderDocumentLine from 'shared/classes/PurchaseOrderDocumentLine'
import PurchaseOrderDocumentLineFromInventory from 'shared/classes/PurchaseOrderDocumentLineFromInventory'
//import SalesOrderDocumentLineFromVehicle from 'shared/classes/SalesOrderDocumentLineFromVehicle'

//save states
// 'NEW'
// 'MODIFIED'
// 'UNMODIFIED'

const floatsAreEqual = (valA, valB) => parseFloat(valA) === parseFloat(valB)
const markup = decimalVal => (decimalVal * 0.01) + 1

function getAddressFromStore(store) {
	return {
		shippingCompany: store.name,
		shippingContact: '',
		shippingStreetAddress: store.address,
		shippingMailingAddress: '',
		shippingCity: store.city,
		shippingState: store.state,
		shippingZip: store.zip,
		shippingCountry: store.country,
		shippingPhoneNumber: store.phoneNumber,
	}
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.purchase-order',
		route: 'purchase-order',
		querystringParameters: [ 'purchaseOrderId' ],
		template: {
			transitions: {
				fly: ractiveTransitionsFly,
			},
			template,
			data: {
				domValue,
				formatCurrency: currency.format,
				floatsAreEqual,
			},
			components: {
				vendorAddress: makeAddressCard(),
				shippingAddress: makeAddressCard(),
				itInputTwo: makeInput({ twoway: true, lazy: false }),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itButton: makeButton(),
				itSelect: makeSelect(),
				itSelectTwo: makeSelect({ twoway: true, lazy: false }),
				itInput: makeInput(),
				itTextArea: makeTextArea(),
				itTextAreaTwo: makeTextArea({ twoway: true, lazy: false }),
				documentLoadModal: makeDocumentLoadModal(),
				customerSelectionModal: makeEntitySelectionModal(mediator),
				vendorSelectionModal: makeEntitySelectionModal(mediator),
				itTable: makeTable(),
				itCheckbox: makeCheckbox(),
				purchaseOrderLineRow: makePurchaseOrderLineRow(),
				itModal: makeModal(),
				reportSelectionModal: makeReportSelection({ mediator, logAndAlert }),
				itYesNoModal: makeYesNoModal(),
				itSplitButton: makeSplitButton(),
			},
			computed: {
				displaySalesPeople() {
					return this.get('salesPeople').reduce((sum, salesperson) => {
						if (salesperson.userName === this.get('purchaseOrder.salesperson') || salesperson.status === 'Active') {
							let displayName = (salesperson.firstName && salesperson.lastName)
								? `${salesperson.firstName} ${salesperson.lastName}`
								: salesperson.userName

							displayName += salesperson.status !== 'Active' ? ' (Inactive)' : ''

							return sum.concat({ ...salesperson, displayName })
						}
						return sum
					}, [])
				},
				displayPurchaseOrderHistory() {
					return this.get('purchaseOrder.purchaseOrderHistory').map(history => {
						const totalQuantityReceived = this.get('purchaseOrder.purchaseOrderLineHistory').reduce((sum, line) => {
							return line.purchaseOrderHistoryId === history.purchaseOrderHistoryId ? sum + line.quantityReceived : sum
						}, 0)

						return {
							...history, displayDate: formatDate(parseISO(history.time), 'MM/dd/yyyy h:mm a'),
							totalQuantityReceived,
						}
					})
				},
				displayPurchaseOrderLineHistory() {
					const currentPurchaseOrderHistoryId = this.get('currentPurchaseOrderHistoryId')
					const inventoryList = this.get('purchaseOrder.inventoryList')
					const purchaseOrderLines = this.get('purchaseOrder.purchaseOrderLines')

					return this.get('purchaseOrder.purchaseOrderLineHistory')
						.filter(lineHistory => lineHistory.purchaseOrderHistoryId === currentPurchaseOrderHistoryId)
						.map(lineHistory => {
							const line = purchaseOrderLines.find(line => line.purchaseOrderLineId === lineHistory.purchaseOrderLineId)
							const inventoryItem = inventoryList.find(item => item.inventoryId === line.inventoryId)

							return {
								...lineHistory, tagNumber: inventoryItem ? inventoryItem.tagNumber : '',
								description: line ? line.description : '',
							}
						})
				},
				hasValidShip() {
					const isDropship = this.get('isDropship')
					const customer = this.get('purchaseOrder.customer')

					return !isDropship || (isDropship && customer && customer.customerId)
				},
				canSave() {
					const purchaseOrder = this.get('purchaseOrder')
					const hasValidShip = this.get('hasValidShip')

					return purchaseOrder.purchaseOrderLines.length > 0 &&
						purchaseOrder.vendor &&
						purchaseOrder.vendor.vendorId &&
						hasValidShip
				},
				updatePricingItems() {
					const { purchaseOrderLines, inventoryList } = this.get('purchaseOrder')

					return purchaseOrderLines.map(line => {
						const inventory = inventoryList.find(item => item.inventoryId === line.inventoryId)

						return {
							inventoryId: inventory?.inventoryId ? inventory.inventoryId : null,
							tagNumber: inventory?.tagNumber ? inventory.tagNumber : '',
							description: line.description,
							oldWholesale: inventory?.wholesalePrice ? parseFloat(inventory.wholesalePrice) : 0,
							oldRetail: inventory?.retailPrice ? parseFloat(inventory.retailPrice) : 0,
							oldCost: inventory?.cost ? parseFloat(inventory.cost) : 0,
							newReceived: line.newReceived,
							lineCost: line.price,
						}
					}).filter(({ inventoryId, newReceived, lineCost, oldCost }) => {
						return inventoryId && newReceived && parseFloat(lineCost) !== oldCost && lineCost
					})
				},
				quantitySum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						return sum.plus(line.quantity.toString())
					}, financialNumber('0')).toString()
				},
				quantityReceivedSum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						return sum.plus(line.newReceived.toString())
							.plus(line.quantityReceived.toString())
					}, financialNumber('0')).toString()
				},
				displayReceivedSum() {
					return `${this.get('quantityReceivedSum')}/${this.get('quantitySum')}`
				},
				newReceivedSum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						return sum.plus(line.newReceived.toString())
					}, financialNumber('0')).toString()
				},
				coreAmountSum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						return sum.plus(line.coreAmount.toString())
					}, financialNumber('0')).toString()
				},
				priceSum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						return sum.plus(line.price.toString())
					}, financialNumber('0')).toString()
				},
				orderedSum() {
					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						const lineTotal = financialNumber(line.price.toString())
							.plus(line.coreAmount.toString())
							.times(line.quantity.toString())
							.toString()

						return sum.plus(lineTotal)
					}, financialNumber('0')).toString()
				},
				hasTax() {
					return !!parseFloat(this.get('purchaseOrder.tax'))
				},
				orderedSumWithTax() {
					const orderedSum = this.get('orderedSum')
					const hasTax = this.get('hasTax')

					if (hasTax) {
						return financialNumber(orderedSum.toString())
							.plus(this.get('purchaseOrder.tax').toString()).toString()
					} else {
						return orderedSum
					}
				},
				receivedSum() {
					const allLinesSum = this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						const totalQuantityReceived = financialNumber(line.quantityReceived.toString()).plus(line.newReceived.toString())

						const lineTotal = financialNumber(line.price.toString())
							//.plus(line.coreAmount.toString()) //apparently core amount is not included in total received
							.times(totalQuantityReceived)
							.toString()

						return sum.plus(lineTotal)
					}, financialNumber('0')).toString()

					return financialNumber(allLinesSum.toString()).plus(this.get('purchaseOrder.tax').toString()).toString()
				},
				retailPriceSum() {
					const inventoryList = this.get('purchaseOrder.inventoryList')

					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						const inventoryItem = inventoryList.find(item => item.inventoryId === line.inventoryId)

						return (inventoryItem && inventoryItem.retailPrice) ? sum.plus(inventoryItem.retailPrice.toString()) : sum
					}, financialNumber('0')).toString()
				},
				listPriceSum() {
					const inventoryList = this.get('purchaseOrder.inventoryList')

					return this.get('purchaseOrder.purchaseOrderLines').reduce((sum, line) => {
						const inventoryItem = inventoryList.find(item => item.inventoryId === line.inventoryId)

						return (inventoryItem && inventoryItem.listPrice) ? sum.plus(inventoryItem.listPrice.toString()) : sum
					}, financialNumber('0')).toString()
				},
			},
			clearShipToAddress() {
				this.set('purchaseOrder', {
					...this.get('purchaseOrder'),
					customer: null,
					shippingCity: '',
					shippingCompany: '',
					shippingContact: '',
					shippingCountry: '',
					shippingMailingAddress: '',
					shippingPhoneNumber: '',
					shippingState: '',
					shippingStreetAddress: '',
					shippingZip: '',
				}).then(() => {
					this.find('#customerLookupInput').select()
				})
			},
			changeVendor() {
				this.set('purchaseOrder.vendor', null).then(() => {
					this.find('#vendorLookupInput').select()
				})
			},
			getInventoryItem(inventoryId) {
				return this.get('purchaseOrder.inventoryList').find(item => item.inventoryId == inventoryId)
			},
			async performAddItemLookup(input, inventoryId, index) {
				const ractive = this

				let search = inventoryId ? { inventoryId } : { lookupString: input }

				const res = await mediator.call('emitToServer', 'sale item lookup', search)
				const { inventoryList, inventoryTypes } = res
				if (inventoryId) {
					inventoryList.forEach(item => ractive.addItem(item, inventoryTypes, index))
				} else if (inventoryList.length === 1) {
					ractive.addItem(inventoryList[0], inventoryTypes, index)
				} else {
					//we should really do something.
					//Probably have a special set of
					//route params that the search screen can handle
				}
			},
			addItem(inventoryItem, inventoryTypeList, index) {
				const ractive = this

				const purchaseOrder = ractive.get('purchaseOrder')
				const PurchaseOrderDocumentLine = new PurchaseOrderDocumentLineFromInventory({
					purchaseOrder,
					inventory: inventoryItem,
					inventoryType: inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === inventoryItem.inventoryTypeId),
					saveState: 'NEW',
				})

				const itemAlreadyExists = ractive.get('purchaseOrder.purchaseOrderLines')
					.find(line => line.inventoryId === PurchaseOrderDocumentLine.inventoryId)

				if (itemAlreadyExists) {
					ractive.findComponent('itYesNoModal')
						.show({
							title: 'Add Item Again?',
							question: 'This item already exists. Add again?',
							yesText: 'Add Again',
							noText: 'Cancel',
							noCallback: 'clearNewLineRow',
							yesCallback: 'addDocumentLineConfirm',
							yesCallbackContext: { PurchaseOrderDocumentLine, inventoryItem, index },
						})
				} else {
					ractive.addDocumentLine({ PurchaseOrderDocumentLine, inventoryItem, index })
				}
			},
			addDocumentLine({ PurchaseOrderDocumentLine, inventoryItem, index }) {
				const ractive = this
				let rank = 0

				const lines = ractive.get('purchaseOrder.purchaseOrderLines')

				if (lines.length > 0) {
					rank = Number(Math.max.apply(Math, lines.map(line => line.rank))) + 1
				}

				PurchaseOrderDocumentLine.uuid = uuid()
				PurchaseOrderDocumentLine.rank = rank

				if (typeof (index) === 'number' && index > -1) {
					ractive.splice('purchaseOrder.purchaseOrderLines', index, 1, PurchaseOrderDocumentLine)
				} else {
					ractive.push('purchaseOrder.purchaseOrderLines', PurchaseOrderDocumentLine)
				}

				ractive.addInventory(inventoryItem)
			},
			addInventory(inventory) {
				if (inventory) {
					this.push('purchaseOrder.inventoryList', inventory)
				}
			},
			addNewLine() {
				const ractive = this
				const { purchaseOrderId } = ractive.get('purchaseOrder')

				const newLine = new PurchaseOrderDocumentLine({
					saveState: 'NEW',
					purchaseOrderLine: { purchaseOrderId },
				})

				ractive.push('purchaseOrder.purchaseOrderLines', newLine).then(() => {
					const lookupInput = ractive.find(`#tag-number-input-${newLine.uuid}`)

					if (lookupInput) {
						lookupInput.select()
					}
				})
			},
			applyPricingMarkup(key, markupPercent) {
				const pricingChanges = this.get('pricingChanges').map(item => {
					return { ...item, [key]: markupPercent }
				})

				this.set({ pricingChanges })
			},
			revertPriceChange(key, index, oldPrice) {
				this.set(`pricingChanges[${index}].${key}`, oldPrice)
			},
			setMarkupPrices() {
				const ractive = this

				const retailMarkupPercent = ractive.get('retailMarkupPercent')
				const wholesaleMarkupPercent = ractive.get('wholesaleMarkupPercent')

				const hasRetailMarkup = retailMarkupPercent && retailMarkupPercent > 0
				const hasWholesaleMarkup = wholesaleMarkupPercent && wholesaleMarkupPercent > 0

				if (hasRetailMarkup || hasWholesaleMarkup) {
					const pricingChanges = ractive.get('pricingChanges').map(item => {
						return {
							...item,
							newRetail: hasRetailMarkup ? markup(retailMarkupPercent) * item.newCost : item.newRetail,
							newWholesale: hasWholesaleMarkup ? markup(wholesaleMarkupPercent) * item.newCost : item.newWholesale,
						}
					})

					ractive.set({ pricingChanges })
				}
			},
			addFromSearch() {
				sessionStorage.setItem('returnState', 'app.purchase-order')
				stateRouter.go('app.part-search')
			},
			addOrRemovePurchaseOrderLineHistory() {
				const ractive = this

				const newHistoryLines = ractive.get('purchaseOrder.purchaseOrderLines')
					.filter(line => line.newReceived)
					.map(line => {
						return {
							purchaseOrderHistoryId: null, //null purchaseOrderHistoryId will tell the server that it's part of this history. It will handle creating a new history row.
							purchaseOrderLineId: line.purchaseOrderLineId,
							quantityReceived: line.newReceived,
							lineUuid: line.uuid,
						}
					})

				const existingHistoryLines = ractive.get('purchaseOrder.purchaseOrderLineHistory')
					.filter(historyLine => historyLine.purchaseOrderHistoryId)

				ractive.set('purchaseOrder.purchaseOrderLineHistory', [
					...existingHistoryLines,
					...newHistoryLines,
				])
			},
			getTagInventoryIdsToPrint() {
				const tagsToPrint = this.get('purchaseOrder.purchaseOrderLines')
					.reduce((acc, { inventoryId, newReceived, printTag, tagPerQuantity }) => {
						if (newReceived && inventoryId && printTag) {
							return [ ...acc, { inventoryId, quantity: tagPerQuantity ? newReceived : 1, tagPerQuantity }]
						} else {
							return acc
						}
					}, [])

				return {
					tagsToPrint,
					someHaveTagPerQuantity: tagsToPrint.some(item => item.tagPerQuantity),
				}
			},
			getEmailSuggestions() {
				const customerEmail = this.get('purchaseOrder.customer.emailAddress')
				const vendorEmail = this.get('purchaseOrder.vendor.emailAddress')

				return {
					[vendorEmail]: vendorEmail,
					[customerEmail]: customerEmail,
				}
			},
		},
		resolve(data, { purchaseOrderId }) {
			if (sessionStorage.getItem('returnState') === 'app.purchase-order') {
				sessionStorage.removeItem('returnState')
			}

			const cachedPurchaseOrder = JSON.parse(localStorage.getItem('purchaseOrderDocument'))

			if (cachedPurchaseOrder && !cachedPurchaseOrder.purchaseOrderId) {
				cachedPurchaseOrder.documentDate = formatISO(new Date(), { representation: 'date' })
			}

			const { user, store } = JSON.parse(localStorage.getItem('session'))
			let newPurchaseOrderTemplate = {
				...getAddressFromStore(store),
				salesperson: (user && typeof user === 'object') ? user.userName : '',
				documentDate: formatISO(new Date(), { representation: 'date' }),
				storeId: 1,
			}

			const newPurchaseOrderDocument = new PurchaseOrderDocument({
				purchaseOrder: newPurchaseOrderTemplate,
				purchaseOrderLines: [],
				purchaseOrderHistory: [],
				purchaseOrderLineHistory: [],
				vendor: null,
				customer: null,
				inventoryList: [],
				saveState: 'NEW',
			})

			return pProps({
				purchaseOrder: (purchaseOrderId) ? mediator.call('emitToServer', 'load purchase order document', { purchaseOrderId }) : (cachedPurchaseOrder || newPurchaseOrderDocument),
				salesPeople: mediator.call('emitToServer', 'load users', {}),
				store,
				loadPurchaseOrderModalShown: false,
				purchaseOrderNotFound: false,
				vendorLookupValue: '',
				customerLookupValue: '',
				postingsModalShown: false,
				isDropship: false,
				sortedPurchaseOrderLines: [],
				sortedPurchaseOrderHistory: [],
				currentPurchaseOrderHistoryId: null,
				printPurchaseOrder: false,
				printReceivingList: false,
				doUpdatePrice: false,
				wantsDocumentCache: true,
				pricingUpdateModalShown: false,
				pricingChanges: [],
				costMarkupPercent: null,
				retailMarkupPercent: null,
				wholesaleMarkupPercent: null,
			})
		},
		activate(context) {
			const { domApi: ractive, parameters } = context

			//Ensure that the totals stored with the document are calculated when they change
			ractive.observe('orderedSum orderedSumWithTax receivedSum', () => {
				ractive.set({
					'purchaseOrder.subtotal': ractive.get('orderedSum'),
					'purchaseOrder.total': ractive.get('orderedSumWithTax'),
					'purchaseOrder.totalReceived': ractive.get('receivedSum'),
				})
			}, { init: false })

			const vendorLookup = ractive.find('#vendorLookupInput')

			if (vendorLookup) {
				vendorLookup.select()
			}

			if (ractive.get('purchaseOrder.purchaseOrderId')) {
				mediator.call('activity', {
					stateName: 'app.purchase-order',
					stateParameters: parameters,
					stateCategory: 'PURCHASE_ORDER',
					action: 'VIEW',
					displayTitle: `Purchase Order #${ractive.get('purchaseOrder.purchaseOrderId')}`,
					stateParameterKey: 'purchaseOrderId',
				})
			}

			if (sessionStorage.getItem('pending-app.purchase-order-item-add')) {
				const pendingItemAdd = JSON.parse(sessionStorage.getItem('pending-app.purchase-order-item-add'))

				if (pendingItemAdd && pendingItemAdd.inventoryIds && pendingItemAdd.inventoryIds.length > 0) {
					ractive.performAddItemLookup(null, pendingItemAdd.inventoryIds)
				}

				sessionStorage.removeItem('pending-app.purchase-order-item-add')
			}

			ractive.on('printPosting', () => {
				ractive.set({ postingsModalShown: false })
				const purchaseOrderId = ractive.get('purchaseOrder.purchaseOrderId')
				const purchaseOrderHistoryId = ractive.get('currentPurchaseOrderHistoryId')

				if (purchaseOrderId && purchaseOrderHistoryId) {
					ractive.findComponent('reportSelectionModal').printReport({
						type: 'Received List',
						reportParameters: [
							{ parameterName: 'ponum', value: parseInt(purchaseOrderId, 10) },
							{ parameterName: 'pohistoryid', value: parseInt(purchaseOrderHistoryId, 10) },
						],
					}, ractive.getEmailSuggestions())
				} else {
					throw 'Error printing tag report'
				}
			})

			ractive.observe('isDropship', isDropship => {
				if (isDropship) {
					ractive.set('purchaseOrder.customer', null).then(() => {
						ractive.clearShipToAddress()
						ractive.find('#customerLookupInput').select()
					})
				} else {
					ractive.set({
						purchaseOrder: {
							...ractive.get('purchaseOrder'),
							...getAddressFromStore(ractive.get('store')),
							customer: {},
						},
					})
				}
			}, { init: false })

			ractive.on('getPONumber', async() => {
				try {
					const purchaseOrder = await mediator.call('emitToServer', 'get purchase order number', {})
					ractive.set('purchaseOrder.purchaseOrderId', purchaseOrder.purchaseOrderId)
				} catch (err) {
					logAndAlert(err, mediator, 'Error getting purchase order number')
				}
			})

			ractive.on('showLoadDocumentModal', () => ractive.findComponent('documentLoadModal').fire('show'))

			ractive.on('loadPurchaseOrder', async(context, { documentId: purchaseOrderId }) => {
				try {
					await mediator.call('emitToServer', 'load purchase order document', { purchaseOrderId })

					if (purchaseOrderId === ractive.get('purchaseOrder.purchaseOrderId')) {
						stateRouter.go(null, { purchaseOrderId }, { replace: true })
						// stateRouter.go won't reload the state for the same Id, so we have to get the updated PO from the server manually
						const purchaseOrder = await mediator.call('emitToServer', 'load purchase order document', { purchaseOrderId })
						ractive.set({ purchaseOrder, loadPurchaseOrderModalShown: false })
					} else {
						stateRouter.go(null, { purchaseOrderId }, { replace: true })
					}
				} catch (err) {
					await ractive.set({ purchaseOrderNotFound: true })
					ractive.findComponent('documentLoadModal')?.focusFirstControl?.()
				}
			})

			ractive.on('openVendor', (context, id) => {
				stateRouter.go('app.entity', { entityContext: 'vendor', id })
			})

			ractive.on('tagNumberDblClick', (context, id) => {
				stateRouter.go('app.part', { inventoryId: id })
			})

			ractive.on('vendorLookup', (context, event, lookup) => {
				event.original.preventDefault()
				ractive.findComponent('vendorSelectionModal').fire('entityLookup', {}, lookup)
			})

			ractive.on('vendorChosen', (context, chosenVendor) => {
				if (chosenVendor === undefined) {
					console.log("[vendorChosen handler] chosenVendor is undefined, abort")
					return
				}
				ractive.set({ vendorLookupValue: chosenVendor.label })

				ractive.set('purchaseOrder.vendor', chosenVendor.data)
				ractive.set('purchaseOrder.vendorId', chosenVendor.id)
			})

			ractive.on('openCustomer', (context, id) => {
				stateRouter.go('app.entity', { entityContext: 'customer', id })
			})

			ractive.on('customerLookup', (context, event, lookup) => {
				event.original.preventDefault()
				ractive.findComponent('customerSelectionModal').fire('entityLookup', {}, lookup)
			})

			ractive.on('customerChosen', (context, chosenCustomer) => {
				if (chosenCustomer === undefined) {
					console.log("[customerChosen handler] customer is undefined, abort")
					return
				}
				const shippingCustomer = Object.keys(chosenCustomer.data).reduce((acc, key) => {
					if (key.startsWith('shipping')) {
						return { ...acc, [key]: chosenCustomer.data[key] }
					}
					return acc
				}, {})

				const purchaseOrder = {
					...ractive.get('purchaseOrder'),
					...shippingCustomer,
					customer: chosenCustomer.data,
				}

				ractive.set({
					customerLookupValue: chosenCustomer.label,
					purchaseOrder,
				})
			})

			ractive.on('receiveAll', () => {
				const purchaseOrderLines = ractive.get('purchaseOrder.purchaseOrderLines').map(line => {
					const { quantity, quantityReceived } = line
					let quantityNeeded = quantity - quantityReceived

					if (quantityNeeded < 0) {
						quantityNeeded = 0
					}

					return { ...line, newReceived: quantityNeeded }
				})

				ractive.set('purchaseOrder.purchaseOrderLines', purchaseOrderLines)
			})

			ractive.on('deleteRow', (context, deleteRow) => {
				const deleteIndex = ractive.get('purchaseOrder.purchaseOrderLines')
					.findIndex(line => line.uuid === deleteRow.uuid)

				if (deleteIndex > -1) {
					ractive.splice('purchaseOrder.purchaseOrderLines', deleteIndex, 1)
				}
			})

			ractive.on('clear', () => {
				ractive.set({ wantsDocumentCache: false }).then(() => {
					localStorage.removeItem('purchaseOrderDocument')
					stateRouter.go('app.redirect', { state: 'app.purchase-order' })
				})
			})

			ractive.on('addDocumentLineConfirm', (context, data) => ractive.addDocumentLine(data))

			ractive.on('partLookup', (context, node, index) => {
				const inputValue = domValue(node).trim()

				if (inputValue) {
					ractive.performAddItemLookup(inputValue, null, index)
				}
			})

			ractive.on('void', async() => {
				const purchaseOrder = ractive.get('purchaseOrder')

				if (purchaseOrder?.purchaseOrderId) {
					try {
						await mediator.call('emitToServer', 'void purchase order', { purchaseOrderId: purchaseOrder.purchaseOrderId })
						stateRouter.go('app.redirect', { state: 'app.purchase-order' })
					} catch (err) {
						logAndAlert(err, mediator, 'Error voiding purchase order')
					}
				}
			})

			ractive.on('save', async() => {
				const purchaseOrderDocument = ractive.get('purchaseOrder')
				const askToClose = purchaseOrderDocument.purchaseOrderLines.every(line => {
					const { quantity, quantityReceived, newReceived } = line
					return (quantityReceived + newReceived) >= quantity
				})
				if (askToClose && confirm("All quantity has been received on this purchase order. Do you want to mark this purchase order as closed?")) {
					ractive.set('purchaseOrderDocument.isClosed', true)
				}
				try {
					const purchaseOrderId = await mediator.call('emitToServer', 'save purchase order document', { purchaseOrderDocument })

					if (ractive.get('printPurchaseOrder') && purchaseOrderId) {
						ractive.findComponent('reportSelectionModal').printReport({
							type: 'Purchase Order',
							reportParameters: [
								{ parameterName: 'purchaseordernum', value: parseInt(purchaseOrderId, 10) },
							],
						}, ractive.getEmailSuggestions())
					}
					// Print tags
					const { tagsToPrint, someHaveTagPerQuantity } = ractive.getTagInventoryIdsToPrint()
					const storeId = ractive.get('purchaseOrder.storeId')

					if (tagsToPrint.length && storeId) { // print tags
						//TODO: this report should support taking a tuple of IDs instead
						//of this screen having to provide an entire WHERE clause(dangerous)
						const reportJobs = tagsToPrint.map(tag => {
							return {
								type: 'Tags',
								quantity: tag.quantity || 1,
								reportParameters: [
									{ parameterName: 'tagnums', value: `inventory.partnum = ${tag.inventoryId}` },
									{ parameterName: 'store', value: parseInt(storeId, 10) },
								],
							}
						})

						ractive.findComponent('reportSelectionModal')?.printReport?.(reportJobs, ractive.getEmailSuggestions(), { disablePrintQuantity: someHaveTagPerQuantity })
					}
					stateRouter.go(null, { purchaseOrderId })
					// stateRouter.go won't reload the state for the same Id, so we have to get the updated PO from the server manually
					const purchaseOrder = await mediator.call('emitToServer', 'load purchase order document', { purchaseOrderId })
					ractive.set({ purchaseOrder })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving purchase order')
				}

				//This will have to be after Save returns successfully and we'll need the purchaseOrderHistoryId

				/*if (ractive.get('printReceivingList') && purchaseOrderId && purchaseOrderHistoryId) {
					ractive.findComponent('reportSelectionModal').printReport({
						type: 'Received List',
						reportParameters: [
							{ parameterName: 'ponum', value: parseInt(purchaseOrderId, 10) },
							{ parameterName: 'pohistoryid', value: parseInt(purchaseOrderHistoryId, 10) },
						],
					})
				}*/

				if (ractive.get('doUpdatePrice')) {
					const pricingChanges = ractive.get('updatePricingItems').map(item => {
						return {
							...item,
							newCost: item.lineCost,
							newRetail: item.oldRetail,
							newWholesale: item.oldWholesale,
						}
					})

					await ractive.set({
						pricingUpdateModalShown: true,
						pricingChanges,
					})

					const pricingInputs = ractive.findAll('.update-pricing-input')

					if (pricingInputs && pricingInputs.length > 0) {
						pricingInputs[0].select()
					}
				}
			})

			ractive.observe('retailMarkupPercent wholesaleMarkupPercent pricingChanges.*.newCost', () => {
				ractive.setMarkupPrices()
			}, { init: false })

			context.on('destroy', () => {
				if (ractive.get('wantsDocumentCache')) {
					const purchaseOrder = ractive.get('purchaseOrder')

					localStorage.setItem('purchaseOrderDocument', JSON.stringify(purchaseOrder))
				}
			})

			ractive.observe('purchaseOrder.purchaseOrderLines', () => {
				ractive.addOrRemovePurchaseOrderLineHistory()
			}, { init: false })
		},
	})
}
